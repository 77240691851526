export const createSlug = (title) => {
  return (
    title.replace(/\s+/g, '-').replace(/[^a-zA-Z-]/g, '').toLowerCase()
  );
}

export const bookSlug = (book_title, nid) => {
  const title = createSlug(book_title);
  return (
    `book/${title}-${nid}`
  )
}

export const chapterSlug = (chapter_title, chapter_number, parent_slug) => {
  const chapterSlug = createSlug(chapter_title);
  return (
    `${parent_slug}/${chapter_number}-${chapterSlug}`
  )
}
