import React from "react"
import { chapterSlug } from '../../utilities/helpers'
import { Link } from 'gatsby'

const Chapters = ({ chapters, parent_url }) => (

  chapters.map(({ drupal_id, chapter_title, chapter_number }) => {
    let chapter_slug = chapterSlug(chapter_title, chapter_number, parent_url)
    return (
      <li className="chapter" key={drupal_id}>
        <div>
          <Link to={chapter_slug} >
            Chapter {chapter_number}: {chapter_title}
          </Link>
        </div>
      </li>
    )
  })

)

export default Chapters;
