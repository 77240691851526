import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';

import Chapters from '../components/books/Chapters';
import { createSlug } from "../utilities/helpers";

const SingleBookTemplate = ({ data, pageContext }) => {
  const { christianBook } = data;
  const { relationships, title, body } = christianBook;
  const { author } = relationships
  const { url } = pageContext;

  const authorSlug = createSlug(author.title);


  return (
    <Layout>

      <section class="title__wrapper">
        <h1>{title}</h1>
      </section>

      <section className="container">
        <span>By </span>
        <Link to={`/author/${authorSlug}-${author.drupal_internal__nid}`} >
          {author.title}
        </Link>

        <div>
          <div dangerouslySetInnerHTML={{ __html: body.processed }}></div>
          <div>
            <h2 className="chapter__book-title">Chapters</h2>
            <ul className="chapter__list">
              <Chapters chapters={relationships.chapters} parent_url={url} />
            </ul>
          </div>
        </div>

      </section>

    </Layout>
  )
}

export default SingleBookTemplate;

export const query = graphql`
query($id: Int!) {
  christianBook(drupal_internal__nid: { eq: $id }) {
    title
    body {
      processed
    }
    drupal_internal__nid
    relationships {
      author {
        title
        drupal_internal__nid
      }
      chapters {
        chapter_number
        chapter_title
        drupal_id
        body {
          processed
        }
      }
    }
  }
}
`
